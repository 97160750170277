import '../styles/pages/old.scss';
import '../styles/pages/corp.scss';
import * as React from "react"
import {HeadFC, navigate} from "gatsby"
import PageService, {HomePageComponents} from "../services/pageService";
import {useEffect, useMemo, useState, useRef} from "react";
import {
    BigLogoImage, apple, LoadingGif, arrRight2, lang
} from "../images";
import {Link} from "gatsby";
import {ApiComponentType} from "../types/ApiComponent";
import HomeOverview from "../components/HomeOverview";
import HomeInfoBlock from "../components/HomeInfoBlock";
// import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";
import MobileNavigation from '../components/MobileNavigation';
import HomeStatistics2 from '../components/HomeStatistics2';
import HomeScreenshots2 from '../components/HomeScreenshots2';
import LearnBlock from '../components/LearnBlock';
import LearnBlockList2 from "../components/LearnBlockList2";
import MainFooter2 from '../components/MainFooter2';
import {toast, ToastContainer} from "react-toastify";
import Header from '../components/Header';
import useAnalytics from "../hooks/useAnalytics";
import AIAeer from '../components/AIAeer';

const lleumeria1Page = () => {
    const [isLoading, setLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(false);
    const [data, setData] = useState<HomePageComponents>([]);
    const [showMenu, setShowMenu] = useState(false);
    const [email, setEmail] = useState('');
    const [formStatus, setFormStatus] = useState(false);
    const {initAnalytics, saveEmail} = useAnalytics();

    useEffect(() => {
        initAnalytics()
    }, []);

    const inputRef = useRef();

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }

    const showForm = (status) => {
        setFormStatus(status);
        status ? inputRef.current.focus() : null;
    }

    const triggerViewContent = () => {
        // fbq("track", "ViewContent");
        console.log('ViewContent');
        navigate('https://aeer.onelink.me/MldY/cl72zqmy');
    }

    const onFormSubmit =async () => {
        if (email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
            setFormLoading(true);
            await saveEmail(email);
            // fbq("track", "Subscribe");
            localStorage.setItem('email', email);
            navigate('/solutions/trial');
        } else {
            setFormLoading(false);
            toast('Invalid e-mail address', {
                autoClose: 5000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        }
    }

    useEffect(() => {
        PageService.getCourseById(1).then((result) => {
            setData(result.data.data.attributes.Content)
        }).catch(() => {
        }).finally(() => {
            setLoading(false)
        });
    }, []);

    const renderContent = useMemo(() => {
        return data.map((content, index) => {
            switch (content.__component) {
                case ApiComponentType.HOME_OVERVIEW: {
                    return (
                        <HomeOverview key={`item-${index}`} data={content}/>
                    )
                }

                // case ApiComponentType.HOME_INFO_BLOCK: {
                //     return (
                //         <HomeInfoBlock key={`item-${index}`} data={content}/>
                //     )
                // }

                // case ApiComponentType.LEARN_BLOCK_LIST: {
                //     return (
                //         <LearnBlock key={`item-${index}`} showForm={showForm} data={content} />
                //     )
                // }

                case ApiComponentType.LEARN_BLOCK_LIST: {
                    return (
                        <div key="learnBlockKey">
                            <LearnBlockList2 key={`learn-list-${index}`} showForm={showForm} data={content} btnType={'appStore'} triggerViewContent={triggerViewContent} />
                            <AIAeer key={`ai-aeer-${index}`} data={content} />
                            <LearnBlock key={`item-${index}`} showForm={showForm} data={content} btnType={'appStore'} triggerViewContent={triggerViewContent} />
                        </div>
                    )
                }

                case ApiComponentType.STATISTICS_GRID: {
                    return (
                        // <HomeStatistics key={`item-${index}`} data={content}/>
                        <HomeStatistics2 key={`item-${index}`} data={content} />
                    )
                }

                case ApiComponentType.APP_SCREENSHOTS: {
                    return (
                        // <HomeScreenshots key={`item-${index}`} data={content}/>
                        <HomeScreenshots2 key={`item-${index}`} showForm={showForm} data={content} btnType={'appStore'} triggerViewContent={triggerViewContent} />
                    )
                }
            }
        })
    }, [data])

    return (
        <main>
            {
                isLoading && (
                    <div className="loadWrap">
                        <div className="loadWrapLogo">
                            <img width={180} src={BigLogoImage} alt=""/>
                            <div className="loadWrapText">
                                <img width={16} src={LoadingGif} alt=""/>
                                Loading..
                            </div>
                        </div>
                    </div>
                )
            }
            <Header toggleMenu={toggleMenu} />

            <div className="content">
                {renderContent}

                <div className="buttonBlock2" id={'preorder'}>
                    {/* <Link to="/payment" className="btnv2 btn-primary">
                        <img src={Crown2} className="btn-img" width={30} alt=""/>
                        <span>Try AEER</span>
                        <img src={arrRight2} className="btn-img" width={30} alt=""/>
                    </Link> */}
                    <a onClick={() => triggerViewContent()} className="btnv2 btn-primary noEventsInside" id="pageCorpTryBtnSecond">
                        <img src={apple} className="btn-img apple" width={24} alt=""/>
                        <span>Download on the App Store</span>
                        <img src={arrRight2} className="btn-img" width={30} alt=""/>
                    </a>
                </div>

                <div className={`blurFormWrap ${formStatus ? 'active' : ''}`}>
                    <div className="blurFormOverlay" onClick={() => showForm(false)}></div>
                    <div className="blurForm">
                        <div className="blurFormTitle">Let's customize the aeer app for you</div>
                        <div className="blurForm">
                            <div className={`solutionsEmailInput`}>
                                <p className="solutionsEmailTitle">Enter your email to continue</p>
                                <label htmlFor="email">Email</label>
                                <input id="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    placeholder="Please enter your email address"
                                    autoComplete="off"
                                    ref={inputRef} />
                                <button onClick={() => onFormSubmit()} className="solutionsBtn" id="pageCorpSignUpBtn">Sign Up</button>
                            </div>
                            <p className="solutionsSecure static">Your information is 100% secure. AEER Platform does not share your personal information with third parties</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* <MainFooter /> */}
            <MainFooter2 showForm={showForm} btnType={'appStore'} linkTo={'https://aeer.onelink.me/MldY/cl72zqmy'} />

            {showMenu && (
                <MobileNavigation onClick={toggleMenu} relation="Corp" />
            )}

            {formLoading && (
                <div className="loaderWrap">
                    <div className="loader">
                        <img width={44} src={LoadingGif} alt=""/>
                    </div>
                </div>
            )}

            <ToastContainer/>
        </main>
    )
}

export default lleumeria1Page

export const Head: HeadFC = () => <title>AEER Platform</title>
